.filter__header_sub-indicator {
  .filters__header_label {
    background: #dedede;
    padding: 2px 10px;
    border-radius: 4px;
    margin-left: 0.5em;
    font-size: 12px;
    max-width: fit-content;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
}

.filters__header_toggle {
  .fas {
    font-size: 16px;
  }
}

.filters__header_info {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  .fa {
    font-size: 16px;
    width: 100%;
    text-align: center;

  }
}

.ui-tooltip {
  background-color: #555 !important;
  color: #fff !important;
  text-align: center !important;
  padding: 5px !important;
  border-radius: 6px !important;
  transition: opacity .6s !important;
  border: 0 !important;
  box-shadow: none !important;
  font-size: 12px !important;
}

.arrow {
  width: 50px;
  height: 10px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -35px;
  bottom: -9px;

  &:after {
    background: #555 !important;
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .top {
    top: -10px;
    bottom: auto;

    &:after {
      bottom: -20px;
      top: auto;
    }
  }

  .left {
    left: 20%;
  }
}

.notification-badges {
  position: relative;

  &:after {
    position: absolute;
    right: -10px;
    top: -6px;
    min-width: 5px;
    min-height: 5px;
    line-height: 8px;
    padding: 5px;
    color: #fff;
    background-color: #2185d0;
    font-size: 8px;
    border-radius: 20px;
    font-family: FontAwesome;
    content: "\f01e";
    border: solid 1px #2185d0;
  }
}

.choropleth-method-qMark {
  background-color: #515151;
  border-radius: 50%;
  color: #fff;
  font-weight: 900;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-left: 20px;
  line-height: 20px;
  cursor: pointer;
}

.choropleth-method-description {
  margin-left: 10px;
  font-size: 0.85em;
}